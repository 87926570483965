export const environment = {
    production: true,
    apiKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImR3ZXhlZHZsb2V2aHpvYW54ZWZwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2Njg1NDM1MjcsImV4cCI6MTk4NDExOTUyN30.7iCMeGLWRiB28Mxh9PyQk0pyFg4ooc9WKird3vr23r4",
    apiUrl: "https://dwexedvloevhzoanxefp.supabase.co",
    shortName: "SoS",
    longName: "Sinfonieorchester",
    showTeachers: false,
    symphonyImage: true,
    withExcuses: true,
    withSignout: true,
    isChoir: false,
};
